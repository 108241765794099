import { Link } from "react-router-dom";

interface ButtonProps {
  text: string;
  type: "link" | "button";
  onClick?: any;
  url?: string;
  target?: string;
}

const Button = ({ text, type, onClick, url, target }: ButtonProps) => {
  return (
    <div className="cursor-pointer mx-auto big-btn py-3 px-7 w-auto rounded-full text-lab hover:text-green">
      {type === "button" ? (
        <button onClick={onClick}>{text}</button>
      ) : (
        <>
          {url && (
            <Link target={target} to={url}>
              {text}
            </Link>
          )}
        </>
      )}
    </div>
  );
};

export default Button;

import Button from "../components/Button";
import { utils } from "ethers";
import { useState, useEffect } from "react";
import ParticlesWrapper from "../components/ParticlesWrapper";
import awsconfig from "../aws-exports";

import { Amplify, API, graphqlOperation } from "aws-amplify";
import { createWallet } from "../graphql/mutations";
Amplify.configure(awsconfig);

function RegisterWallet() {

  const [answered, setAnswered] = useState<boolean>(false);
  const [walletAddress, setWalletAddress] = useState<string>("");
  const [validAddress, setValidAddress] = useState<boolean>(true);
  const [username, setUsername] = useState<string>("");
  const [registered, setRegistered] = useState(false);

  const checkAnswer = (answer:string)=>{
    if (answer==="Three Hundred"){
      setAnswered(true);
    }
  }

  const validateAddress = (address:string)=>{
   
    if(utils.isAddress(address)===false){
      setValidAddress(false);
    }else{
      setValidAddress(true);
    }
    setWalletAddress(address);
  }

  const register = async () => {

    if (!validAddress || walletAddress==="")return;

    const wallet = { address: walletAddress, user: (username==="") ? "anonymous" : username };
    try {
          
      console.log("registering...");

      /* register a wallet */
      const result = await API.graphql(
        graphqlOperation(createWallet, { input: wallet })
      );

      // ignore unique entry exists errors because it's already registered.
      console.log(result);
    } catch (error) {}

    setRegistered(true);
  };

  useEffect(() => {}, []);

  return (
    <main className="bg-lab flex flex-col max-w-2xl mx-auto overflow-hidden p-7 text-center">
      {registered ? (
        <section className="flex flex-col pt-32">
          <h5 className="font-schoolbell text-center md:text-5xl text-3xl text-green leading-none">
            Details received!
          </h5>
          <div className="flex md:flex-row flex-col justify-between items-center">
            <Button text={"Go back"} type={"link"} url="/" />
          </div>
          <ParticlesWrapper />
        </section>
      ) : (
        <section className="flex flex-col pt-24">
          <h5 className="font-schoolbell md:text-5xl text-3xl text-green leading-none">
            Feeling Lucky?
          </h5>

          {
          
          (answered)?
            <>
             <p className="mt-7 font-miriam md:text-xl md:leading-loose leading-loose text-sm">
              Enter your eth address and twitter to register.
             </p>

          <div className="mt-7 font-miriam md:text-xl md:leading-loose leading-loose text-sm flex md:flex-row flex-col space-x-5 items-center">
            <div className="w-full">
              {(!validAddress)&&
              <span>Invalid address</span>
              }
              <input
                placeholder="Eth address"
                className="py-5 px-2 text-lg rounded-full text-center w-full"
                type="text"
                style={{ color: "black" }}
                onChange={(e) => validateAddress(e.target.value) }
              />
            </div>
          </div>

          <div className="mt-7 font-miriam md:text-xl md:leading-loose leading-loose text-sm flex md:flex-row flex-col space-x-5 items-center">
            <div className="w-full">
              <input
                placeholder="@Twitter User"
                className="py-5 px-2 text-lg rounded-full text-center w-full"
                type="text"
                style={{ color: "black" }}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
          </div>

          <div className="pt-6 flex md:flex-row flex-col justify-between items-center">
            <Button
              text={"Register"}
              type={"button"}
              onClick={() => register()}
            />
            <Button text={"Go back"} type={"link"} url="/" />
          </div>
            </>
            :
            <>
            <p className="mt-7 font-miriam md:text-xl md:leading-loose leading-loose text-sm">
            Quiz time! Answers can be found on the website.
            </p>

            <div className="mt-5 font-miriam md:text-xl md:leading-loose leading-loose text-sm">
              <p>
              How many Pickle base NFTs are there?
              </p>
              <select className="mt-5 py-5 px-2 text-lg rounded-full text-center"  style={{ color: "black" }}
              onChange={(e)=>checkAnswer(e.target.value)}
              >
                <option selected>One Hundred</option>
                <option>Two Hundred</option>
                <option>Three Hundred</option>
              </select>
            </div>

            <div className="pt-6 flex md:flex-row flex-col justify-between items-center">
            <Button text={"Go back"} type={"link"} url="/" />
          </div>


            </>

          
          }


         
        </section>
      )}
    </main>
  );
}

export default RegisterWallet;

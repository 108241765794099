import { useEffect, useRef, useState } from "react";

interface SectionProps {
  heading: string;
  preamble: string;
  imageAlignment: "left" | "right";
  image: {
    alt?: string;
    src?: string;
    isPickle: boolean;
  };
  audio?: boolean;
}

const Section_6_6 = ({
  heading,
  preamble,
  image,
  imageAlignment,
  audio,
}: SectionProps) => {
  const [open, setOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (audio) {
      audioRef.current?.play();
      setIsPlaying(true);
    }
  }, [open]);

  return (
    <section className="bg-lab h-full flex justify-center items-center py-7 relative">
      <div
        className={`flex flex-col max-w-5xl ${
          imageAlignment === "right" ? "md:flex-row-reverse" : "md:flex-row"
        }`}
      >
        <div className="md:w-1/2 w-full px-7 mt-14 md:text-left text-center">
          <h4 className="font-schoolbell md:text-5xl text-3xl text-green leading-none">
            {heading}
          </h4>
          <p className="mt-7 font-miriam md:text-xl md:leading-loose leading-loose text-sm whitespace-pre-line">
            {preamble.split("<br/>").join("\n")}
          </p>
        </div>
        <div className="md:w-1/2 w-full mt-16">
          {open && (
            <>
              <audio ref={audioRef} src="./Dr._Pickles.mp3" />
              {audio && (
                <>
                  <button
                    className="text-green font-bold text-3xl mt-4 z-50 fixed top-2 left-6 p-3"
                    onClick={() => {
                      if (audioRef.current?.paused) {
                        audioRef.current?.play();
                        setIsPlaying(true);
                      } else {
                        audioRef.current?.pause();
                        setIsPlaying(false);
                      }
                    }}
                  >
                    {isPlaying ? "I I" : "▶"}
                  </button>
                </>
              )}
              <div
                onClick={() => setOpen(false)}
                className="z-40 bg-lab bg-opacity-75  h-full w-full fixed top-0 bottom-0 left-0 right-0 p-7"
              >
                <img
                  className={`absolute top-1/2 h-auto w-full max-w-3xl left-1/2 transform -translate-x-1/2  -translate-y-1/2 ${
                    imageAlignment === "right" ? "-rotate-1" : "rotate-1"
                  }`}
                  src={image.src}
                  alt={image.alt}
                />
              </div>
            </>
          )}
          <img
            onClick={() => setOpen(true)}
            className={`mag-glass md:w-full px-14 mx-auto ${
              imageAlignment === "right" ? "-rotate-1" : "rotate-1"
            } `}
            src={image.src}
            alt={image.alt}
          />
        </div>
      </div>
    </section>
  );
};

export default Section_6_6;

import Button from "../components/Button";
import Section_6_6 from "../components/Section_6_6";
import Section_12 from "../components/Section_12";
import { useEffect } from "react";
import { Link } from "react-router-dom";

function Mint() {
  useEffect(() => {}, []);

  return (
    <main className="bg-lab flex flex-col max-w-screen-2xl mx-auto overflow-hidden h-full items-center justify-center">
      <h1 className="font-schoolbell text-5xl text-green mx-auto">
        Nothing here :'(
      </h1>

      <Button text={"Go back!"} type={"link"} url="/" />
    </main>
  );
}

export default Mint;

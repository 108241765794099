import { useState } from "react";

interface AccordionProps {
  question: string;
  answer: string;
}

const Accordion = ({ question, answer }: AccordionProps) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="w-full mb-3 border-2 border-green rounded-lg py-4 px-7">
      <div
        onClick={() => setOpen(!open)}
        className="cursor-pointer flex justify-between items-center font-schoolbell text-lg hover:text-green"
      >
        <h2>{question}</h2>
        <div className="rotate-90">{open ? "‹" : "›"}</div>
      </div>

      <div className={open ? "pt-7 pb-3 font-miriam text-sm" : "hidden"}>
        {answer}
      </div>
    </div>
  );
};

export default Accordion;

import Button from "../components/Button";

import { useState, useEffect } from "react";
import ParticlesWrapper from "../components/ParticlesWrapper";

function WLCheck() {
  const [whitelist, setWhitelist] = useState<string[]>([]);
  const [checkAddress, setCheckAddress] = useState<string>("");
  const [whitelisted, setWhitelisted] = useState(false);

  useEffect(() => {
    setWhitelisted(whitelist.includes(checkAddress.toUpperCase()));
  }, [checkAddress]);

  useEffect(() => {
    fetch(
      "https://opensheet.elk.sh/1B5fXOddNB6Kp5-nYK4U2RujMcEa8fKRIwGp2klFC2xM/whitelist"
    )
      .then((res) => res.json())
      .then(
        (result) => {
          let wl: string[] = [];

          result.forEach((element: { address: string }) => {
            wl.push(element.address.toUpperCase());
          });
          console.log(wl);

          setWhitelist(wl);
        },

        (error) => {
          console.log(error);
        }
      );
  }, []);

  return (
    <main className="bg-lab flex flex-col max-w-2xl mx-auto overflow-hidden p-7 text-center">
      <span>
        <span
          className={
            !whitelisted && checkAddress !== ""
              ? "absolute bottom-0 left-0 md:w-auto w-32 pepe"
              : "absolute bottom-0 -left-64  md:w-auto w-32 pepe"
          }
        >
          <img src="pepe-base.png" />
          <span
            className={
              !whitelisted && checkAddress !== ""
                ? "font-schoolbell md:text-lg text-sm absolute top-8 md:-right-64 -right-52"
                : "hidden"
            }
          >
            Sorry, you're not on the list
          </span>
        </span>
      </span>
      {whitelisted ? (
        <section className="flex flex-col pt-32">
          <h5 className="font-schoolbell text-center md:text-5xl text-3xl text-green leading-none">
            You are on the list!
          </h5>
          <div className="my-7 text-center font-miriam md:text-xl md:leading-loose leading-loose text-sm">
            Paste your address below and we'll check if you are lucky!
          </div>
          <div className="flex md:flex-row flex-col justify-between items-center">
            <Button
              text={"Check another"}
              type={"button"}
              onClick={() => setCheckAddress("")}
            />
            <Button text={"Go back"} type={"link"} url="/" />
          </div>
          <ParticlesWrapper />
        </section>
      ) : (
        <section className="flex flex-col pt-24">
          <h5 className="font-schoolbell md:text-5xl text-3xl text-green leading-none">
            Whitelist Checker
          </h5>
          <p className="mt-7 font-miriam md:text-xl md:leading-loose leading-loose text-sm">
            Paste your address below and we'll check if you are lucky!
          </p>

          <div className="mt-7 font-miriam md:text-xl md:leading-loose leading-loose text-sm flex md:flex-row flex-col space-x-5 items-center">
            <div className="w-full">
              <input
                placeholder="vitalik.eth"
                className="py-5 px-2 text-lg rounded-full text-center w-full"
                type="text"
                style={{ color: "black" }}
                onChange={(e) => setCheckAddress(e.target.value.toUpperCase())}
              />
            </div>
          </div>
        </section>
      )}
    </main>
  );
}

export default WLCheck;

import { useMachine } from "@xstate/react";
import { ConnectKitButton } from "connectkit";
import { useEffect, useRef, useState } from "react";
import { utils } from "ethers";
import {
  useContractRead,
  useContract,
  useSigner,
  useAccount,
  useWatchPendingTransactions,
} from "wagmi";
import { readContracts , waitForTransaction } from '@wagmi/core'
import { useInterval } from "../hooks/useInterval";

import { NFTABI } from "../contracts/contractABI";

const CONTRACT_ADDRESS = "0x98b1297E6e53f8F595d9560069aE5E4869BA95b3";
const MAX_SUPPLY = 1200;

const phases = new Map();
phases.set(0, "WHITELIST");
phases.set(1, "RESERVED");
phases.set(2, "PUBLIC");
phases.set(3, "MINT COMPLETE");

function Mint() {
  const [mintAmount, setMintAmount] = useState(2);
  const [mintPrice, setMintPrice] = useState("0");
  const [totalSupply, setTotalSupply] = useState("0");
  const [minting, setMinting] = useState(false);
  const [maxMintAmount, setMaxMintAmount] = useState(2);
  const [paused, setPaused] = useState(false);
  const [userBalance, setBalance] = useState("0");
  const [error, setError] = useState(false);
  const { address } = useAccount();

  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [subtitle, setSubtitle] = useState("");
  const [phase, setPhase] = useState("");
  const [isOG, setIsOG] = useState(false);
  const [isWL, setIsWL] = useState(false);

  const playSoundWithSubtitles = (soundFile: string, subtitle: string) => {
    if (audio !== null) {
      audio.pause();
      audio.volume=0.5;
      setIsPlaying(false);
    }

    const audioElement = new Audio(soundFile);
    setAudio(audioElement);
    audioElement.play();
    setIsPlaying(true);
    setSubtitle(subtitle);

    audioElement.addEventListener("ended", () => {
      setIsPlaying(false);
    });
  };

  useContractRead({
    address: CONTRACT_ADDRESS,
    abi: NFTABI,
    functionName: "paused",
    onSuccess: (res) => {
      let paused = res as boolean;
      setPaused(paused);
    },
  });

  useContractRead({
    address: CONTRACT_ADDRESS,
    abi: NFTABI,
    functionName: "MINT_PRICE",
    onSuccess: (res) => {
      let price = res as any;
      setMintPrice(utils.formatEther(price["_hex"]));
    },
  });

  useContractRead({
    address: CONTRACT_ADDRESS,
    abi: NFTABI,
    functionName: "totalSupply",
    onSuccess: (res) => {
      let supply = res as any;
      setTotalSupply(utils.formatUnits(supply["_hex"], 0));
    },
  });

  useContractRead({
    address: CONTRACT_ADDRESS,
    abi: NFTABI,
    functionName: "getCurrentMintPhase",
    onSuccess: (res) => {
      let phase = res as any;
      setPhase(phases.get(phase));
    },
  });

  const { data: signer } = useSigner();

  useContractRead({
    address: CONTRACT_ADDRESS,
    abi: NFTABI,
    functionName: "OG_ALLOW_LIST",
    args: [address],
    onSuccess: (res) => {
      let og = res as any;
      setIsOG(og);
    },
  });

  const contract = useContract({
    address: CONTRACT_ADDRESS,
    abi: NFTABI,
    signerOrProvider: signer,
  });

  // CONTRACT POLLING
  const CONTRACT_POLLING_INTERVAL = 3000;

  useInterval(() => {
    try {
      watchContract();
    } catch (error) {
      console.log(error);
    }
  }, CONTRACT_POLLING_INTERVAL);

  const watchContract = async()=>{
    const data = await readContracts({
      contracts: [
        {
          address: CONTRACT_ADDRESS,
          abi: NFTABI,
          functionName: 'totalSupply',
        },
        {
          address: CONTRACT_ADDRESS,
          abi: NFTABI,
          functionName: 'getCurrentMintPhase',
        },
        {
          address: CONTRACT_ADDRESS,
          abi: NFTABI,
          functionName: "balanceOf",
          args: [address],
        },
        {
          address: CONTRACT_ADDRESS,
          abi: NFTABI,
          functionName: "getMaxCanMint",
          args: [address],
        },
        {
          address: CONTRACT_ADDRESS,
          abi: NFTABI,
          functionName: 'paused',
        },
      ],
    })
    //console.log(data);

    let supply = data[0] as any;
    //console.log(utils.formatUnits(supply["_hex"], 0));
    setTotalSupply(utils.formatUnits(supply["_hex"], 0));

    let phase = data[1] as any;
    //console.log(phase);
    setPhase(phases.get(phase));

    let balance = data[2] as any;
    //console.log("Balance: " +utils.formatUnits(balance["_hex"], 0) );
    setBalance(utils.formatUnits(balance["_hex"], 0));

    let maxMint = data[3] as any;
    //console.log("maxMint: " +utils.formatUnits(maxMint["_hex"], 0) );
    let maxMintInt = parseInt(utils.formatUnits(maxMint["_hex"], 0));
    setMaxMintAmount(maxMintInt);

    if (maxMintInt<mintAmount || mintAmount==0){
      setMintAmount(maxMintInt);
    }

    let paused = data[4] as boolean;
    //console.log(paused);
    setPaused(paused);

  }

  const onMintClick = async () => {

    if (phase=="WHITELIST" && !isWL){
      console.log("Sorry not on WL");
      return;
    }

    if (phase=="RESERVED" && !isOG){
      console.log("Sorry not on OG list");
      return;
    }

    if ((MAX_SUPPLY===parseInt(totalSupply))){
      console.log("Sorry mint is complete.");
      return;
    }

    setMinting(true);
    playSoundWithSubtitles("./mint.mp3", "Sit still, this won't hurt a bit!");
    try {
      const { hash } = await contract?.mint(mintAmount, {
        value: utils.parseEther(
          (mintAmount * parseFloat(mintPrice)).toString()
        ),
      });
      console.log(hash);

      const data = await waitForTransaction({
        hash,
      }) 

      console.log(data);
      playSoundWithSubtitles("./This_is_for_science.mp3", "This is for science!");

    } catch (error) {}

    setMinting(false);
  };

  useContractRead({
    address: CONTRACT_ADDRESS,
    abi: NFTABI,
    functionName: "balanceOf",
    args: [address],
    onSuccess: (res) => {
      let supply = res as any;
      setBalance(utils.formatUnits(supply["_hex"], 0));
    },
  });

  useEffect(() => {
    if (!signer) return;
  }, [signer]);

  useEffect(() => {
    fetch(
      "https://opensheet.elk.sh/1B5fXOddNB6Kp5-nYK4U2RujMcEa8fKRIwGp2klFC2xM/whitelist"
    )
      .then((res) => res.json())
      .then(
        (result) => {
          //console.log(result)
          let wl: string[] = [];

          result.forEach((element: { address: string }) => {
            wl.push(element.address.toUpperCase());
          });
          address && wl.indexOf(address.toUpperCase()) > -1 && setIsWL(true);
          address && wl.indexOf(address.toUpperCase()) == -1 && setIsWL(false);
        },

        (error) => {
          console.log(error);
        }
      );
  }, [signer]);

  const handleAmountChange = (dir: string) => {
    if (dir === "up") {
      if (mintAmount + 1 <= maxMintAmount) {
        setMintAmount(mintAmount + 1);
      }
    } else {
      if (mintAmount - 1 > 0) {
        setMintAmount(mintAmount - 1);
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setError(false);
    }, 3000);
  }, [error]);

  return (
    <main
      id="mint-bg"
      className="bg-lab h-full items-center justify-center flex flex-col max-w-screen-2xl mx-auto overflow-hidden"
    >
      <div className="z-50 absolute top-4 right-4">
        <ConnectKitButton />
      </div>
      {audio && isPlaying && <audio src={audio.src} />}
      {isPlaying && (
        <p
          id="subtitle"
          className="bg-black p-2 z-50 font-consolas leading-none text-2xl absolute bottom-8"
        >
          Dr. Pickles: {subtitle}
        </p>
      )}
      <div id="foreground"></div>

      <div id="mint" className="w-2/3 max-w-lg">
        <div id="mint-dash" className="relative">
          <span id="mint-amount" className="text-right">
            {error ? <span className="blinking">err</span> : mintAmount}
          </span>

          <span id="eth-amount" className="text-right">
            {error ? (
              <span className="blinking">err</span>
            ) : (
              parseFloat(mintPrice) * mintAmount
            )}
          </span>
          {(paused)?
          <span id="phase">WAITING...</span>
          :
          <span id="phase">{(MAX_SUPPLY===parseInt(totalSupply)) ? "MINT COMPLETE": phase}</span>
          }
          
          <span id="mint-count">
            {error ? (
              <span className="blinking">err</span>
            ) : (
              <span>{totalSupply}</span>
            )}
          </span>

          <span id="user-count">{signer ? userBalance : "-"}</span>

          <span id="power-indicator"></span>
          <span
            id="og-indicator"
            className={isOG ? "indicator-green" : "indicator-red"}
          ></span>
          <span
            id="wl-indicator"
            className={isWL ? "indicator-green" : "indicator-red"}
          ></span>

          {minting && <span className="blinking" id="minting-indicator"></span>}

          <button
            onClick={() => {
              handleAmountChange("up");
            }}
            id="plus-btn"
          ></button>
          <button
            onClick={() => {
              handleAmountChange("down");
            }}
            id="minus-btn"
          ></button>
          <button
            disabled = {(mintAmount==0 || "MINT COMPLETE"==phase || paused)}
            onClick={() => signer && onMintClick()}
            id="mint-btn"
          ></button>

          <img className="w-full" src="bg.svg" />
        </div>
      </div>
    </main>
  );
}

export default Mint;

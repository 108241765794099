import Button from "./Button";

const Footer = () => {
  return (
    <footer className="bg-lab pt-14 relative">
      <div className="flex md:px-14 px-7 md:flex-row flex-col-reverse md:items-end items-start max-w-7xl mx-auto justify-between absolute bottom-0 left-0 right-0">
        <div className="font-schoolbell text-base py-7">
          © Dr Pickles World. 2024.
        </div>

        <div className="md:mb-0 mb-7 py-14">
          <div className=" md:block hidden">
            <Button
              text={"Join our Telegram"}
              type={"link"}
              url="https://t.me/Drpicklesportal"
            />
          </div>

          <div className="flex md:justify-end space-x-5 md:mt-7 md:mb-0 mt-0 mb-14 md:relative absolute md:right-auto right-14 md:top-auto -top-7">
            {/* *
            <a href="https://discord.gg/zW7ThBvyKs" target="_blank">
              <img src="./discord.svg" alt="Discord" />
            </a> */}
            <a href="https://X.com/drpicklesworld" target="_blank">
              <img src="./twitter.svg" alt="Discord" />
            </a>
          </div>
        </div>
      </div>
      <img src="./footer_bg.png" className="w-full h-auto mx-auto" />
    </footer>
  );
};

export default Footer;

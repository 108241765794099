interface CardProps {
  image: {
    src: string;
  };
  type: "Human" | "Pickle" | "Ape" | "Pepe";
  bio: string;
}

const Card = ({ image, type, bio }: CardProps) => {
  return (
    <div className="md:w-1/2 w-full max-w-xs mx-auto mt-14">
      <img src={image.src} alt={type} className="w-full" />
      <div className="bg-lab p-5 text-justify relative  border-green border-2 rounded-xl">
        <h4 className="font-schoolbell bg-lab px-7 rounded-full py-2 border-green border-2 text-green text-2xl absolute -top-6 left-1/2 transform -translate-x-1/2">
          {type}
        </h4>
        <p className="text-sm font-miriam pt-7">{bio}</p>
      </div>
    </div>
  );
};

export default Card;
